import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="currentColor" {...props}>
    <path d="M64 128a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm64 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zM64 384a64 64 0 1 0 128 0 64 64 0 1 0-128 0zm64 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm256-288a64 64 0 1 0 0-128 64 64 0 1 0 0 128zm96-64a96 96 0 1 1-192 0 96 96 0 1 1 192 0zm-96 144c8.8 0 16 7.2 16 16v80h80c8.8 0 16 7.2 16 16s-7.2 16-16 16h-80v80c0 8.8-7.2 16-16 16s-16-7.2-16-16v-80h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h80v-80c0-8.8 7.2-16 16-16z" />
  </svg>
);

export default SvgComponent;
