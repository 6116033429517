import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor" {...props}>
    <path d="M64 32h448c17.7 0 32 14.3 32 32v134.6c11.2 3.2 21.9 7.4 32 12.6V64c0-35.3-28.7-64-64-64H64C28.7 0 0 28.7 0 64v288c0 35.3 28.7 64 64 64h149.1l-10.7 64H144c-8.8 0-16 7.2-16 16s7.2 16 16 16h250.8c-12.9-9.1-24.5-19.9-34.6-32H234.9l10.7-64h81c-2.9-10.3-4.9-21-5.9-32H64c-17.7 0-32-14.3-32-32V64c0-17.7 14.3-32 32-32zm416 160.7V112c0-8.8-7.2-16-16-16h-96c-8.8 0-16 7.2-16 16s7.2 16 16 16h57.4L304 249.4l-68.7-68.7c-6.2-6.2-16.4-6.2-22.6 0l-112 112c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L224 214.6l68.7 68.7c3 3 7.1 4.7 11.3 4.7s8.3-1.7 11.3-4.7L448 150.6v48c10.3-2.9 21-4.9 32-5.9zm16 63.3a112 112 0 1 1 0 224 112 112 0 1 1 0-224zm0 256a144 144 0 1 0 0-288 144 144 0 1 0 0 288zm-23.2-176.6c.6-.9 1.8-2.1 4.2-3.4 5.1-2.7 12.5-4.1 18.7-4 8.2.1 17.1 1.8 26.4 4.1 8.6 2.1 17.3-3.1 19.4-11.7s-3.1-17.3-11.7-19.4c-5.6-1.4-11.6-2.7-17.9-3.7V288c0-8.8-7.2-16-16-16s-16 7.2-16 16v9.5c-6.1 1.2-12.3 3.2-18 6.3-11.8 6.3-23 18.4-21.8 37.2 1 16 11.7 25.3 21.6 30.7 8.8 4.7 19.7 7.8 28.6 10.3l1.8.5c10.3 2.9 17.9 5.2 23.2 8.3 4.5 2.7 4.7 4.2 4.7 5.6.1 2.4-.5 3.7-1 4.5-.6 1-1.8 2.2-4 3.3-4.7 2.5-11.8 3.8-18.5 3.6-9.5-.3-18.5-3.1-29.9-6.8-1.9-.6-3.8-1.2-5.8-1.8-8.4-2.6-17.4 2.1-20 10.5s2.1 17.4 10.5 20c1.6.5 3.3 1 5 1.6 7 2.3 15.1 4.8 23.7 6.6v11.4c0 8.8 7.2 16 16 16s16-7.2 16-16v-10.6c6.2-1.1 12.5-3.1 18.3-6.2 12.1-6.5 22.3-18.7 21.7-36.9-.5-16.2-10.3-26.3-20.5-32.3-9.4-5.6-21.2-8.9-30.5-11.5h-.2c-10.4-2.9-18.3-5.2-23.9-8.2-4.8-2.6-4.8-4-4.8-4.5v-.1c-.1-1.9.3-2.9.8-3.6z" />
  </svg>
);

export default SvgComponent;
