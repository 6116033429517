import { ILayoutContextData, LayoutReducerActionType, ILayoutReducerAction } from '@/types/layout';

export const layoutReducer = (state: ILayoutContextData, action: ILayoutReducerAction) => {
  switch (action.type) {
    case LayoutReducerActionType.isModalOpen:
      return {
        ...state,
        isModalOpen: action.payload,
      };
    default:
      return state;
  }
};
