'use client';

import { PropsWithChildren } from 'react';

import { LayoutProvider } from '@/contexts/LayoutContext';
import { LayoutFooter } from './LayoutFooter';
import { LayoutHeader } from './LayoutHeader';

export const Layout = (props: PropsWithChildren<unknown>) => {
  return (
    <LayoutProvider>
      <div className={styles.layoutBase}>
        <div className={styles.layoutBlock}>
          <header className="font-header">
            <LayoutHeader />
          </header>
          <main className="font-body">{props.children}</main>
          <footer className="font-footer">
            <LayoutFooter />
          </footer>
        </div>
      </div>
    </LayoutProvider>
  );
};

const styles = {
  layoutBase: 'p-4 md:p-12 h-full bg-gradient-to-r from-zinc-200 via-zinc-100 to-zinc-200',
  layoutBlock:
    'container mx-auto flex flex-col justify-between h-full bg-gradient-to-br from-stone-50 to-stone-100 border border-white shadow-xl rounded-lg',
};
