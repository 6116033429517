import IconEmail from '@/icons/regular-envelope';
import IconInstagram from '@/icons/brands-instagram';
import IconLinkedin from '@/icons/brands-linkedin';
import IconX from '@/icons/brands-x';

const LayoutHeaderNavbar = () => {
  return <div className=""></div>;
};

const LayoutHeaderSocial = () => {
  return (
    <div className="flex flex-row gap-3 items-center">
      <div className="">
        <IconLinkedin className="w-6 h-6 text-neutral-800" />
      </div>
      <div className="">
        <a href="https://x.com/inZycle_labs" className="">
          <IconX className="w-6 h-6 text-neutral-800" />
        </a>
      </div>
      <div className="">
        <a href="https://instagram.com/inzycle" className="">
          <IconInstagram className="w-6 h-6 text-neutral-800" />
        </a>
      </div>
      <div className="">
        <a href="mailto:contact@inzycle.com" className="">
          <IconEmail className="w-6 h-6 text-neutral-800" />
        </a>
      </div>
    </div>
  );
};

export const LayoutHeader = () => {
  return (
    <div className="flex flex-row items-center justify-between p-4">
      <LayoutHeaderNavbar />
      <LayoutHeaderSocial />
    </div>
  );
};
