import IconApp from '@/icons/light-grid-round-2-plus';
import IconCommercial from '@/icons/light-display-chart-up-circle-dollar';
import IconCustom from '@/icons/light-gear-complex-code';
import IconEnterprise from '@/icons/light-city';
import IconWeb from '@/icons/light-browser';

export const LayoutFooter = () => {
  return (
    <div className={styles.footer}>
      <div className={styles.item}>
        <div className="">
          <IconWeb className={styles.itemIcon} />
        </div>
        <div className={styles.itemText}>
          <span className={styles.itemTextLabel}>Web</span>
          <span className={styles.itemTextExtended}>Development</span>
        </div>
      </div>
      <div className={styles.itemSpace} />
      <div className={styles.item}>
        <div className="">
          <IconApp className={styles.itemIcon} />
        </div>
        <div className={styles.itemText}>
          <span className={styles.itemTextLabel}>Mobile</span>
          <span className={styles.itemTextExtended}>Development</span>
        </div>
      </div>
      <div className={styles.itemSpace} />
      <div className={styles.item}>
        <div className="">
          <IconCommercial className={styles.itemIcon} />
        </div>
        <div className={styles.itemText}>
          <span className={styles.itemTextLabel}>Commercial</span>
          <span className={styles.itemTextExtended}>Development</span>
        </div>
      </div>
      <div className={styles.itemSpace} />
      <div className={styles.item}>
        <div className="">
          <IconEnterprise className={styles.itemIcon} />
        </div>
        <div className={styles.itemText}>
          <span className={styles.itemTextLabel}>Enterprise</span>
          <span className={styles.itemTextExtended}>Development</span>
        </div>
      </div>
      <div className={styles.itemSpace} />
      <div className={styles.item}>
        <div className="">
          <IconCustom className={styles.itemIcon} />
        </div>
        <div className={styles.itemText}>
          <span className={styles.itemTextLabel}>Custom</span>
          <span className={styles.itemTextExtended}>Development</span>
        </div>
      </div>
      <div className={styles.itemPlaceholder}>
        <span className={styles.itemPlaceholderText}>Development</span>
      </div>
    </div>
  );
};

const styles = {
  footer:
    'relative bg-[#8FBA5C] rounded-b-lg flex flex-row gap-0 items-center justify-center p-4 pb-10 md:gap-3 md:justify-start md:p-8 md:bg-transparent',
  itemSpace: 'hidden border-r border-neutral-200 h-8 md:block',
  item: 'flex flex-col gap-2 items-center w-1/5 md:gap-4 md:max-w-24',
  itemIcon:
    'w-6 h-6 text-white md:w-10 md:h-10 md:text-neutral-800 md:hover:animate-pulse md:hover:text-[#8FBA5C] transition-colors',
  itemText: 'text-[10px] text-white text-center leading-none md:text-xs md:text-sm md:text-neutral-500',
  itemTextLabel: 'inline-block',
  itemTextExtended: 'hidden md:inline-block',
  itemPlaceholder:
    'absolute left-8 right-8 bottom-4 text-[10px] text-center text-white md:hidden border-b border-white',
  itemPlaceholderText: 'inline-block bg-[#8FBA5C] px-6 translate-y-1/2',
};
