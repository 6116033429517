/* eslint-disable @typescript-eslint/no-explicit-any */

export interface ILayoutContextData {
  isModalOpen?: boolean;
}

export enum LayoutReducerActionType {
  isModalOpen = 'isModalOpen',
}

export interface ILayoutReducerAction {
  type: LayoutReducerActionType;
  payload?: any;
}
