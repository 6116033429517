import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" fill="currentColor" {...props}>
    <path d="M96 16v48h64V16c0-8.8 7.2-16 16-16s16 7.2 16 16v80H64c-17.7 0-32 14.3-32 32v320c0 17.7 14.3 32 32 32h133.5c4.2 11.9 10.7 22.8 19 32H64c-35.3 0-64-28.7-64-64V128c0-35.3 28.7-64 64-64V16c0-8.8 7.2-16 16-16s16 7.2 16 16zm416 176c-17.7 0-32-14.3-32-32V64c0-17.7-14.3-32-32-32H288c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h288c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32h-64zm0-128v96h64c35.3 0 64 28.7 64 64v224c0 35.3-28.7 64-64 64H288c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64h160c35.3 0 64 28.7 64 64zM320 392v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24zm32-8h32v-32h-32v32zm-8-320h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24V88c0-13.3 10.7-24 24-24zm8 64h32V96h-32v32zm-32 136v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24zm32-8h32v-32h-32v32zm152 192c-13.3 0-24-10.7-24-24v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48zm8-64v32h32v-32h-32zm-32-88v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24h-48c-13.3 0-24-10.7-24-24zm32-8h32v-32h-32v32zM88 352c-13.3 0-24-10.7-24-24v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88zm8-64v32h32v-32H96zm-32-88v-48c0-13.3 10.7-24 24-24h48c13.3 0 24 10.7 24 24v48c0 13.3-10.7 24-24 24H88c-13.3 0-24-10.7-24-24zm32-8h32v-32H96v32z" />
  </svg>
);

export default SvgComponent;
